import 'performance-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';

const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT;

if (SENTRY_ENVIRONMENT) {
  const APP_VERSION = process.env.APOLLO_VERSION;
  if (SENTRY_ENVIRONMENT === 'production') {
    Sentry.init({
      dsn: 'https://dbddc92e11734706ae8b0056e494028c@sentry.io/1495373',
      release: `apollo@${APP_VERSION}-${__GIT_SHA__}`,
      environment: SENTRY_ENVIRONMENT,
      whitelistUrls: [
        /https:\/\/d1p5cqqchvbqmy\.cloudfront\.net\/web\/release\/.*/i,
      ],
    });
  } else {
    Sentry.init({
      dsn: 'https://512273fdc95d432eb6492c0c4a85c61f@sentry.io/1495373',
      release: `apollo@${APP_VERSION}-${__GIT_SHA__}`,
      environment: SENTRY_ENVIRONMENT,
    });
  }
}

const appType = process.env.APPTYPE;
const root = document.getElementById('mappedin-app');

const App = () => {
  const [content, setContent] = useState(<div />);

  useEffect(() => {
    const load = async () => {
      let module;
      switch (appType) {
        case 'mall':
          module = await import(/* webpackChunkName: "app" */ './index-mall');
          break;
        case 'health':
          module = await import(/* webpackChunkName: "app" */ './index-health');
          break;
        case 'school':
          module = await import(/* webpackChunkName: "app" */ './index-school');
          break;
        default:
          module = await import(/* webpackChunkName: "app" */ './index-mall');
          break;
      }
      const el = React.createElement(module.default);
      setContent(el);
    };
    load();
  }, []);

  return <React.Fragment>{content}</React.Fragment>;
};

render(<App />, root);
